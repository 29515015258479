<template>
  <v-card flat>
    <v-card-text class="px-6 py-4" v-if="adm_id && login_radius">
      <v-row v-if="!checkingCliente">
        <!-- Status -->
        <v-col cols="12">
          <v-card outlined>
            <v-card-title class="text-body-2 lightgray pa-3" v-if="radacct">
              Status de conexão do cliente:
              <v-spacer></v-spacer>
              <div v-if="radacct.acctstoptime" class="font-weight-bold">
                DESCONECTADO A
                {{ conectadoDiff(radacct.acctstoptime).toUpperCase() }}
              </div>
              <div class="text-subtitle-1 font-weight-bold" v-else>
                CONECTADO A
                {{ conectadoDiff(radacct.acctstarttime).toUpperCase() }}
              </div>
            </v-card-title>
            <v-card-title class="text-body-2 lightgray pa-3" v-else>
              Status de conexão do cliente:
              <v-spacer></v-spacer>
              <div class="text-subtitle-1 font-weight-bold">
                SEM CONEXÃO
              </div>
            </v-card-title>
          </v-card>
        </v-col>

        <!-- Radacct -->
        <v-col cols="12" v-if="radacct">
          <v-card outlined>
            <v-card-title class="text-body-2 px-3 py-2 lightgray">
              Radacct
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Chave
                    </th>
                    <th class="text-left">
                      Valor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key, index) in radacct" :key="index">
                    <td>{{ key }}</td>
                    <td
                      v-if="key === 'acctstarttime' || key === 'acctstoptime'"
                    >
                      {{ value | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                    </td>
                    <td v-else>{{ value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <!-- Registro -->
        <v-col cols="12" v-if="sis_cliente">
          <v-card outlined>
            <v-card-title class="text-body-2 px-3 py-2 lightgray">
              Registro
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Chave
                    </th>
                    <th class="text-left">
                      Valor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key, index) in sis_cliente" :key="index">
                    <td>{{ key }}</td>
                    <td
                      v-if="
                        key === 'data_bloq' ||
                          key === 'data_desbloq' ||
                          key === 'data_desativacao'
                      "
                    >
                      {{ value | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                    </td>
                    <td v-else>{{ value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <Carregando v-else />
    </v-card-text>
    <v-card-text v-else>
      Cliente nao contem adm_id ou login_radius.
    </v-card-text>
  </v-card>
</template>

<script>
import { getUltConexao, getSisCliente } from "@/services/api/conexoes.api.js";
import { format, parseISO, formatDistance } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

export default {
  name: "ClienteConexoes",

  props: {
    cliente_id: {
      type: [Number, String],
      required: true,
    },
    adm_id: {
      type: [Number, String],
      required: true,
    },
    login_radius: {
      type: String,
      required: true,
    },
    cliente_nome: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      buscandoAdmCliente: false,
      radacct: null,
      sis_cliente: null,
      checkingCliente: false,
    };
  },

  methods: {
    conectadoDiff(campo) {
      if (this.radacct) {
        return formatDistance(new Date(), parseISO(campo), {
          locale: ptBrLocale,
        });
      } else {
        return "";
      }
    },
    async checkCliente() {
      this.checkingCliente = true;
      this.radacct = null;
      this.sis_cliente = null;

      await getUltConexao(`?pppoe=${this.login_radius}`)
        .then((response) => {
          this.radacct = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error("No hay nenguna conexion en radius!");
          }
        });

      await getSisCliente(`?pppoe=${this.login_radius}`)
        .then((response) => {
          this.sis_cliente = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error("No hay nengun registro en radius!");
          }
        });

      this.checkingCliente = false;
    },
  },

  mounted() {
    this.checkCliente();
  },
};
</script>

<style></style>
